<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center">
            <div>{{ responseData.courseName }} > {{ responseData.sectionName }}</div>
            <div>제출: {{ responseData.mbId }}</div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 d-flex justify-content-between align-items-center p-0">
              <div class="btn-area text-end mb-2">
                <select v-model="passStatus" class="passSelect" @change="passUpdate">
                  <option value="0">대기</option>
                  <option value="1">통과</option>
                  <option value="2">실패</option>
                </select>
              </div>
              <div class="btn-area text-end mb-2">
                <button class="btn btn-danger" @click="runCode('run')">실행</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <ul class="tab">
                <li>
                  <button
                    v-if="responseData.cQuizType.includes('html')"
                    class="btn"
                    :class="{on: tab === 'html'}"
                    @click="tabChange('html')"
                  >
                    HTML
                  </button>
                  <button
                    v-if="responseData.cQuizType.includes('css')"
                    class="btn"
                    :class="{on: tab === 'css'}"
                    @click="tabChange('css')"
                  >
                    CSS
                  </button>
                  <button
                    v-if="
                      responseData.cQuizType.includes('javascript') ||
                        responseData.cQuizType.includes('js')
                    "
                    class="btn"
                    :class="{on: tab === 'js'}"
                    @click="tabChange('js')"
                  >
                    JS
                  </button>
                  <button
                    v-if="
                      responseData.cQuizType.includes('typescript') ||
                        responseData.cQuizType.includes('ts')
                    "
                    class="btn"
                    :class="{on: tab === 'ts'}"
                    @click="tabChange('ts')"
                  >
                    TS
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <form action="#" @submit.prevent="typeForm">
            <!-- row -->
            <div class="row">
              <div class="col-md-6 col-sm-6 left-note" v-html="responseData.content"></div>
              <div class="col-md-6 col-sm-6">
                <AceEditor
                  v-model="content"
                  @init="editorInit"
                  :lang="editorMode"
                  theme="monokai"
                  width="100%"
                  height="500px"
                  :commands="[
                    {
                      name: 'save',
                      bindKey: {win: 'Ctrl-s', mac: 'Command-s'},
                      exec: dataSumit,
                      readOnly: true
                    }
                  ]"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="card">
        <div class="row">
          <div class="col-12">
            <div class="terminal" ref="print">
              Waiting for code execution..
            </div>
          </div>
        </div>
      </div>

      <!-- 리플 -->
      <div class="reply mb-3">
        <HomeworkDetailItem v-for="item in insertReplyData" :key="`new${item.idx}`" :item="item" />
        <HomeworkDetailItem v-for="item in replyData" :key="item.idx" :item="item" />
      </div>

      <div class="reply-write">
        <CkeditorNuxtBasic v-model="typeform.content" :config="config" />
        <div class="text-end">
          <button class="btn btn-secondary mt-3" @click="$router.back()">취소</button>
          <button class="btn btn-primary mt-3" @click="replyAdd">등록</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import he from 'he/he';
import AceEditor from 'vuejs-ace-editor';
import CkeditorNuxtBasic from '@/components/editor/CkeditorNuxtBasic.vue';
import HomeworkDetailItem from '@/components/homework/HomeworkDetailItem.vue';

export default {
  components: {
    AceEditor,
    CkeditorNuxtBasic,
    HomeworkDetailItem
  },
  props: {
    responseData: {
      type: Object,
      default() {
        return {};
      }
    },
    replyData: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    editorMode() {
      if (this.tab === 'html') return 'html';
      else if (this.tab === 'css') return 'css';
      else if (this.tab === 'js') return 'javascript';
      else if (this.tab === 'ts') return 'typescript';
      else if (this.tab === 'python') return 'python';
      else return 'html';
    }
  },
  data() {
    return {
      tab: '',
      content: '',
      passStatus: 0,
      typeform: {
        content: ''
      },
      insertReplyData: [],
      config: {
        removePlugins: ['MediaEmbedToolbar'],
        codeBlock: {
          languages: [
            {language: 'css', label: 'CSS'},
            {language: 'html', label: 'HTML'},
            {language: 'javascript', label: 'JavaScript'},
            {language: 'python', label: 'Python'},
            {language: 'typescript', label: 'TypeScript'}
          ]
        },
        link: {
          // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
          addTargetToExternalLinks: true,

          // Let the users control the "download" attribute of each link.
          decorators: [
            {
              mode: 'manual',
              label: 'Downloadable',
              attributes: {
                download: 'download'
              }
            }
          ]
        },
        image: {
          resizeUnit: '%',
          resizeOptions: [
            {
              name: 'resizeImage:original',
              value: null
            },
            {
              name: 'resizeImage:25',
              value: '25'
            },
            {
              name: 'resizeImage:50',
              value: '50'
            },
            {
              name: 'resizeImage:75',
              value: '75'
            }
          ],
          toolbar: [
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
            '|',
            'toggleImageCaption',
            'imageTextAlternative',
            'resizeImage'
          ]
        }
        // toolbar: {
        //   items: [
        //     'heading',
        //     '|',
        //     'fontFamily',
        //     'fontSize',
        //     'fontColor',
        //     'fontBackgroundColor',
        //     'highlight',
        //     '|',
        //     'bold',
        //     'italic',
        //     '|',
        //     'imageUpload',
        //     'link',
        //     '|',
        //     'codeBlock',
        //     'insertTable',
        //     '|',
        //     'bulletedList',
        //     'numberedList',
        //     '|',
        //     'outdent',
        //     'indent',
        //     'undo',
        //     'redo'
        //   ]
        // }
      },
      //editor
      editor: {
        type: 'html',
        correctType: 'hand',
        correct: '',
        html: `<!DOCTYPE html>
<html lang="ko">
<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
</head>
<body>

</body>
</html>`,
        css: ``,
        javascript: ``,
        python: ``,
        typescript: ``
      }
    };
  },
  mounted() {
    // 퀴즈 타입에 따른 요청 파라미터 구분
    if (this.responseData.cQuizType === 'html') {
      this.tab = 'html';
      this.content = this.decode(this.responseData.cHtml);
      this.editor.html = this.decode(this.responseData.cHtml);
    } else if (this.responseData.cQuizType === 'html+css') {
      this.tab = 'html';
      this.content = this.decode(this.responseData.cHtml);
      this.editor.html = this.decode(this.responseData.cHtml);
      this.editor.css = this.decode(this.responseData.cCss);
    } else if (this.responseData.cQuizType === 'javascript') {
      this.tab = 'js';
      this.content = this.decode(this.responseData.cJs);
      this.editor.javascript = this.decode(this.responseData.cJs);
    } else if (this.responseData.cQuizType === 'typescript') {
      this.tab = 'ts';
      this.content = this.decode(this.responseData.cTypescript);
      this.editor.typescript = this.decode(this.responseData.cTypescript);
    } else if (this.responseData.cQuizType === 'python') {
      this.tab = 'python';
      this.content = this.decode(this.responseData.cPython);
      this.editor.python = this.decode(this.responseData.cPython);
    } else if (this.responseData.cQuizType === 'html+css+js') {
      this.tab = 'html';
      this.content = this.decode(this.responseData.cHtml);
      this.editor.html = this.decode(this.responseData.cHtml);
      this.editor.css = this.decode(this.responseData.cCss);
      this.editor.javascript = this.decode(this.responseData.cJs);
    }
  },
  methods: {
    editorInit: function(editor) {
      require('brace/ext/language_tools'); //language extension prerequsite...
      require('brace/mode/html');
      require('brace/mode/javascript'); //language
      require('brace/mode/typescript'); //language
      require('brace/mode/less');
      require('brace/theme/monokai');
      require('brace/snippets/javascript'); //snippet
      require('brace/snippets/typescript'); //snippet
      require(['emmet/emmet'], function(data) {
        window.emmet = data.emmet;
      });
      editor.setOption('enableEmmet', true);
      editor.getSession().setUseWrapMode(true);
      editor.setOptions({
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        fontSize: 14,
        highlightActiveLine: true,
        // enableSnippets: true,
        showLineNumbers: true,
        tabSize: 2,
        showPrintMargin: false,
        showGutter: true
      });
    },
    dataSumit() {
      console.log('save');
    },
    async replyAdd() {
      const res = await this.$store.dispatch('post/quizReplyAdd', {
        idx: this.responseData.idx,
        desc: this.typeform.content
      });

      this.insertReplyData = this.insertReplyData.concat(res.data);
      this.$confirm({
        title: res.result ? 'Success' : 'Error',
        message: '답변이 등록되었습니다.',
        button: {
          yes: '확인'
        }
      });
    },
    runCode(excuteType) {
      // 기본 요청 파라미터
      const requestObj = {
        cIdx: this.responseData.cIdx,
        sIdx: this.responseData.sIdx,
        pIdx: this.responseData.pIdx,
        quizType: this.responseData.cQuizType,
        excuteType
      };

      // 퀴즈 타입에 따른 요청 파라미터 구분
      if (this.responseData.cQuizType === 'html') {
        requestObj.html = this.editor.html;
      } else if (this.responseData.cQuizType === 'html+css') {
        requestObj.html = this.editor.html;
        requestObj.css = this.editor.css;
      } else if (this.responseData.cQuizType === 'html+css+js') {
        requestObj.html = this.editor.html;
        requestObj.css = this.editor.css;
        requestObj.js = this.editor.javascript;
      } else if (this.responseData.cQuizType === 'javascript') {
        requestObj.js = this.editor.javascript;
      } else if (this.responseData.cQuizType === 'typescript') {
        requestObj.ts = this.editor.typescript;
      } else if (this.responseData.cQuizType === 'python') {
        requestObj.python = this.editor.python;
      }

      if (excuteType === 'run') this.excuteCode(requestObj);
    },
    async excuteCode(requestObj) {
      this.$refs.print.innerHTML = '코드를 실행중입니다.';

      // 코드 실행이 오래 걸릴 경우를 대비해서 화면에 ... 인터렉티브 효과 구현
      const interval = setInterval(() => {
        this.$refs.print.innerHTML += '.';
      }, 2000);

      // 실행
      let output = null;
      if (this.responseData.cQuizType === 'typescript') {
        const result = await (
          await fetch('https://api.codapi.org/v1/exec', {
            method: 'POST',
            headers: {
              // 'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              sandbox: 'typescript',
              command: 'run',
              files: {
                'main.ts': requestObj.ts
              }
            })
          })
        ).json();
        output = result.stdout;
      } else {
        const result = await this.$store.dispatch('post/activeCode', requestObj);
        output = result.output;
      }

      // 퀴즈 타입이 html, html +css 이면 새창으로 파일 오픈
      if (
        this.responseData.cQuizType === 'html' ||
        this.responseData.cQuizType === 'html+css' ||
        this.responseData.cQuizType === 'html+css+js'
      ) {
        this.$refs.print.innerHTML = '<p>코드가 실행되었습니다.</p>';
        window.open('https://run.sucoding.kr' + output, 'runcode');
      }

      // 퀴즈 타입이 javascipt , python이면 실행 결과 출력
      if (
        this.responseData.cQuizType === 'javascript' ||
        this.responseData.cQuizType === 'typescript' ||
        this.responseData.cQuizType === 'python'
      ) {
        this.$refs.print.innerHTML = '';

        if (typeof output !== 'string') {
          output.forEach((v, i) => {
            if (i > 0) this.$refs.print.innerHTML += '<br>';
            this.$refs.print.innerHTML += v;
          });
        } else {
          this.$refs.print.innerHTML = output;
        }
      }
      clearInterval(interval);
    },
    decode(value) {
      if (value === '') return value;
      return he.decode(value);
    },
    async passUpdate() {
      const res = await this.$store.dispatch('post/homeWorkStatus', {
        idx: this.responseData.idx,
        passStatus: this.passStatus
      });

      this.$confirm({
        title: res.result ? 'Success' : 'Error',
        message: res.message,
        button: {
          yes: '확인'
        }
      });
    },
    tabChange(tab) {
      this.tab = tab;
      if (tab === 'html') {
        this.content = this.decode(this.responseData.cHtml);
      } else if (tab === 'css') {
        this.content = this.decode(this.responseData.cCss);
      } else if (tab === 'js') {
        this.content = this.decode(this.responseData.cJs);
      } else if (tab === 'python') {
        this.content = this.decode(this.responseData.cPython);
      }
    }
  }
};
</script>
<style lang="scss">
.terminal {
  p {
    margin-bottom: 0;
  }
}
</style>
<style lang="scss" scoped>
.terminal {
  background: #333;
  color: #ebd1d1;
  padding: 1rem;

  p {
    margin-bottom: 0;
  }
}
.passSelect {
  background: cornflowerblue;
  color: white;
  padding: 7px;
  border-radius: 5px;
  border: none;
}
.left-note {
  padding: 1rem;
  border: 1px solid #ccc;
  height: 500px;
  overflow: scroll;

  &::v-deep img {
    max-width: 100%;
  }
}

.tab {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0;
  li {
    list-style: none;

    button {
      border-radius: 0;
      background-color: #f9f9f9;
      outline: none;
    }

    button.on {
      background-color: #6530e3;
      color: #fff;
    }
  }
}
</style>
