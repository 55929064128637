<template>
  <div v-if="!deleteFlag" class="card">
    <div class="card-body mb-2">
      <div class="d-flex mb-4">
        <img
          v-if="item.userName === '수코딩'"
          :src="require(`@/assets/images/logo.png`)"
          class="flex-shrink-0 me-3 rounded-circle avatar-sm"
        />
        <img
          v-else
          class="flex-shrink-0 me-3 rounded-circle avatar-sm"
          src="@/assets/images/users/user-1.jpg"
          alt="Generic placeholder image"
        />

        <div class="d-flex align-items-start justify-content-center flex-column w-100">
          <h4 class="font-size-15 m-0">{{ item.userName }}</h4>
          <div class="d-flex justify-content-between w-100">
            <small class="text-muted">{{ item.mbId }}</small>
            <small class="text-muted">{{ item.regdate }}</small>
          </div>
        </div>
      </div>
      <div v-if="!modifyFlag" class="item" v-html="modifyEnd ? typeform.content : item.desc"></div>
      <div v-if="modifyFlag" class="item-modify mb-3">
        <CkeditorNuxtBasic v-model="typeform.content" :config="config" />
      </div>

      <!-- 대댓글 -->
      <div class="reply-to-reply">
        <ul>
          <li v-for="reply in item.reply" :key="reply.idx">
            <!-- <img :src="require(`@/assets/images/logo.png`)" /> -->
            <div>
              <p class="info">
                <span>
                  {{ reply.userName }} <span>{{ reply.regdate }}</span>
                </span>
                <span class="float-end" @click="deleteRreply(reply.idx)">삭제</span>
              </p>
              <div>
                {{ reply.desc }}
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div v-if="!modifyFlag" class="replyin">
        <img :src="require(`@/assets/images/logo.png`)" />
        <textarea v-model="replyContent"></textarea>
        <button class="btn btn-primary" @click="replyInreplyAdd">등록</button>
      </div>
      <div class="d-flex justify-content-between">
        <div class="d-flex">
          <button v-if="modifyFlag" class="btn btn-secondary mt-4 me-2" @click="modifyChange">
            <i class="mdi mdi-clipboard-edit-outline"></i> Cancle
          </button>
          <button v-else class="btn btn-primary mt-4 me-2" @click="modifyChange">
            <i class="mdi mdi-clipboard-edit-outline"></i> Modify
          </button>
          <button v-if="modifyFlag" class="btn btn-primary mt-4 me-2" @click="modifyAdd">
            <i class="mdi mdi-clipboard-edit-outline"></i> Modify
          </button>
          <button to="/email/compose" class="btn btn-danger mt-4" @click="deleteReply">
            <i class="mdi mdi-trash-can"></i> Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as hljs from '@/plugins/highlight.min.js';
import '@/assets/css/monokai.min.css';
import CkeditorNuxtBasic from '@/components/editor/CkeditorNuxtBasic.vue';
export default {
  components: {
    CkeditorNuxtBasic
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.highlight();
    }, 10);
  },
  data() {
    return {
      modifyFlag: false,
      modifyEnd: false,
      deleteFlag: false,
      replyContent: '',
      typeform: {
        content: this.item.desc
      },
      config: {
        codeBlock: {
          languages: [
            {language: 'css', label: 'CSS'},
            {language: 'html', label: 'HTML'},
            {language: 'javascript', label: 'JavaScript'},
            {language: 'typescript', label: 'TypeScript'},
            {language: 'python', label: 'Python'}
          ]
        },
        link: {
          // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
          addTargetToExternalLinks: true,

          // Let the users control the "download" attribute of each link.
          decorators: [
            {
              mode: 'manual',
              label: 'Downloadable',
              attributes: {
                download: 'download'
              }
            }
          ]
        },
        image: {
          resizeUnit: '%',
          resizeOptions: [
            {
              name: 'resizeImage:original',
              value: null
            },
            {
              name: 'resizeImage:25',
              value: '25'
            },
            {
              name: 'resizeImage:50',
              value: '50'
            },
            {
              name: 'resizeImage:75',
              value: '75'
            }
          ],
          toolbar: [
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
            '|',
            'toggleImageCaption',
            'imageTextAlternative',
            'resizeImage'
          ]
        }
        // toolbar: {
        //   items: [
        //     'heading',
        //     '|',
        //     'fontFamily',
        //     'fontSize',
        //     'fontColor',
        //     'fontBackgroundColor',
        //     'highlight',
        //     '|',
        //     'bold',
        //     'italic',
        //     '|',
        //     'imageUpload',
        //     'link',
        //     '|',
        //     'codeBlock',
        //     'insertTable',
        //     '|',
        //     'bulletedList',
        //     'numberedList',
        //     '|',
        //     'outdent',
        //     'indent',
        //     'undo',
        //     'redo'
        //   ]
        // }
      }
    };
  },
  methods: {
    highlight() {
      document.querySelectorAll('pre').forEach(block => {
        hljs.highlightBlock(block);
      });
    },
    modifyChange() {
      this.modifyFlag = !this.modifyFlag;
    },
    modifyAdd() {
      const result = this.$store.dispatch('post/quizReplyModify', {
        idx: this.item.idx,
        desc: this.typeform.content
      });

      if (result) {
        this.modifyEnd = true;
        this.modifyFlag = false;
      }
    },
    deleteReply() {
      const result = this.$store.dispatch('post/quizReplyDelete', {
        idx: this.item.idx,
        desc: this.typeform.content
      });

      if (result) {
        this.deleteFlag = true;
      }
    },
    async replyInreplyAdd() {
      const result = await this.$store.dispatch('post/quizReplyInReplyAdd', {
        idx: this.item.idx,
        desc: this.replyContent
      });

      if (result.result) {
        this.replyContent = '';
        this.item.reply.push(result.data);
      }
    },
    async deleteRreply(idx) {
      this.$confirm({
        message: `댓글을 삭제하시겠습니까?`,
        button: {
          no: '취소',
          yes: '삭제'
        },
        callback: async confirm => {
          if (confirm) {
            await this.$store
              .dispatch('post/quizRreplyDelete', {
                idx
              })
              .then(res => {
                this.$confirm({
                  title: res.result ? 'Success' : 'Error',
                  message: res.message,
                  button: {
                    yes: '확인'
                  }
                });

                if (res.result) this.item.reply = this.item.reply.filter(v => v.idx !== idx);
              });
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.replyin {
  display: flex;
  align-items: center;
  margin-top: 20px;
  img {
    width: 50px;
    margin-right: 10px;
  }
  textarea {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    resize: none;
    padding: 5px;
  }
  button {
    margin-left: 10px;
    height: 50px;
    width: 60px;
  }
}

.reply-to-reply {
  ul {
    margin: 0;
    padding: 0;
    li > div {
      width: 100%;
    }
    li {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      background: #f9f9f9;
      border: 1px solid #e9e9e9;
      padding: 5px 14px;
      border-radius: 10px;
      margin-bottom: 10px;

      img {
        width: 40px;
        border-radius: 50%;
      }

      p {
        margin-bottom: 0;
        margin-left: 10px;
      }

      p.info {
        display: flex;
        align-items: center;
        font-size: 14px;
        justify-content: space-between;
        span {
          font-size: 12px;
          margin-left: 5px;
        }
      }
    }
  }
}
::v-deep pre {
  padding: 1rem;
  border-radius: 10px;
}
</style>
