import { render, staticRenderFns } from "./HomeworkDetail.vue?vue&type=template&id=a7d9277c&scoped=true&"
import script from "./HomeworkDetail.vue?vue&type=script&lang=js&"
export * from "./HomeworkDetail.vue?vue&type=script&lang=js&"
import style0 from "./HomeworkDetail.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./HomeworkDetail.vue?vue&type=style&index=1&id=a7d9277c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a7d9277c",
  null
  
)

export default component.exports