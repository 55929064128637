<template>
  <div>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <HomeworkDetail
        v-if="responseData && replyData"
        :response-data="responseData"
        :reply-data="replyData"
      />
    </Layout>
  </div>
</template>

<script>
import Vue from 'vue';
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header.vue';
import HomeworkDetail from '@/components/homework/HomeworkDetail.vue';

export default Vue.extend({
  components: {
    Layout,
    PageHeader,
    HomeworkDetail
  },
  data() {
    return {
      title: 'Homework Detail',
      items: [
        {
          text: 'Sucoding',
          href: '/'
        },
        {
          text: 'Homework',
          href: '/'
        },
        {
          text: 'Detail',
          active: true
        }
      ],
      responseData: null,
      replyData: null
    };
  },
  async created() {
    await this.$store
      .dispatch('post/getHomework', {
        idx: this.$route.params.id
      })
      .then(res => {
        this.responseData = res.list;
        this.replyData = res.reply;
      });
  }
});
</script>
